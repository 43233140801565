.Image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  //border: 14px solid #000;

  // XS - Phone
  @media (max-width: 480px) {
    display: none;
  }

  // S - Tablet
  @media (max-width: 768px) {
    display: none;
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
