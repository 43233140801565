.BlackParagraph {
  margin-bottom: 5%;

  .paragraph-content {
    background-color: var(--bg-black);
    display: flex;
    gap: 20px;
    align-items: center;
    //padding-bottom: 40px;

    .text-content {
      flex: 3;
      padding: 5%;
    }

    .image-content {
      flex: 1;
      width: 300px;
      min-width: 300px;
    }
  }

  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
    .paragraph-content {
      background-color: var(--bg-black);

      .text-content {
        padding: 10%;
        padding-bottom: 20%;
      }

      .image-content {
        display: none !important;
      }
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
    margin-bottom: 5%;
    min-height: 400px;
    max-height: 400px;
    height: 400px;
  }
}
