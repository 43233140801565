.LanguageSwitch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  color: var(--fg-transparent-black);

  .separator {
    margin-left: 10px;
    margin-right: 10px;
  }

  .selector {
    cursor: pointer;
  }

  .selected {
    font-family: var(--main-text-bold);
    color: var(--fg-black);
  }
}
