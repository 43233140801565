.Button {
  background-color: var(--bg-outright-blue);
  color: var(--fg-white);
  border: none !important;
  cursor: pointer;
  font-family: var(--button);
  letter-spacing: 2.5px;
  padding: 5px 10px;
  text-transform: lowercase;
  font-size: 0.8rem;
  display: flex;
  gap: 10px;

  transition: var(--all-transition);

  &:hover {
    background-color: var(--bg-outright-blue-dark);
    box-shadow: var(--box-shadow);
  }

  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
