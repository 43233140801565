.Header {
  margin-bottom: 5%;
  min-height: 400px;
  max-height: 400px;
  height: 400px;

  .logo img {
    //position: absolute;
    height: 80px;
    margin-left: 5%;
    margin-top: -40px;
  }

  .header-content {
    background-color: var(--bg-black);
    display: flex;
    gap: 20px;
    align-items: center;
    //padding-bottom: 40px;

    .text-content {
      flex: 3;
      padding: 5%;
    }

    .image-content {
      width: 300px;
      min-width: 300px;
    }
  }

  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
    margin-bottom: 140px;
    .logo img {
      //position: absolute;
      //height: 80px;
      width: 90%;
      height: auto;
      //margin-left: 20px;
      //margin-right: 20px;
      margin: 20px;
      margin-top: -40px;
    }

    .header-content {
      background-color: var(--bg-black);

      .text-content {
        padding: 10%;
        padding-bottom: 20%;
      }

      .image-content {
        display: none !important;
      }
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
