.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  gap: 20px;

  a {
    color: var(--fg-black-muted);
    transition: var(--all-transition);

    &:hover {
      color: var(--fg-outright-blue);
    }
  }

  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
