/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
a {
  text-decoration: none;
}

/** FONTS **/
/*! main-text-regular */
@font-face {
  font-family: 'main-text-regular';
  src: url('../fonts/HelveticaNeue.woff') format('woff'),
    url('../fonts/HelveticaNeue.woff2') format('woff2');
}
/*! main-text-bold */
@font-face {
  font-family: 'main-text-bold';
  src: url('../fonts/HelveticaNeue.woff') format('woff'),
    url('../fonts/HelveticaNeue.woff2') format('woff2');
}
/*! main-text-link */
@font-face {
  font-family: 'main-text-link';
  src: url('../fonts/HelveticaNeue.woff') format('woff'),
    url('../fonts/HelveticaNeue.woff2') format('woff2');
}
/*! quote */
@font-face {
  font-family: 'quote';
  src: url('../fonts/Georgia.woff') format('woff'),
    url('../fonts/Georgia.woff2') format('woff2');
}
/*! button */
@font-face {
  font-family: 'button';
  src: url('../fonts/AnoRegular-Regular.woff') format('woff'),
    url('../fonts/AnoRegular-Regular.woff2') format('woff2');
}
/*! heading */
@font-face {
  font-family: 'heading';
  src: url('../fonts/AnoRegular-Regular.woff') format('woff'),
    url('../fonts/AnoRegular-Regular.woff2') format('woff2');
}
/** CUSTOM PROPERTIES **/
:root {
  /** COLOR **/
  /*! Background Color */
  /*! black */
  --bg-black: #000000;
  /*! highlight-black */
  --bg-highlight-black: #212121;
  /*! transparent-black */
  --bg-transparent-black: rgba(0, 0, 0, 0.3);
  /*! black-muted */
  --bg-black-muted: rgba(0, 0, 0, 0.6);
  /*! white */
  --bg-white: #fff;
  /*! white-muted */
  --bg-white-muted: rgba(255, 255, 255, 0.6);
  /*! outright-blue */
  --bg-outright-blue: #133f8e;
  /*! outright-blue-dark */
  --bg-outright-blue-dark: #10326e;
  /*! Foreground Color */
  /*! black */
  --fg-black: #000000;
  /*! highlight-black */
  --fg-highlight-black: #212121;
  /*! transparent-black */
  --fg-transparent-black: rgba(0, 0, 0, 0.3);
  /*! black-muted */
  --fg-black-muted: rgba(0, 0, 0, 0.6);
  /*! white */
  --fg-white: #fff;
  /*! white-muted */
  --fg-white-muted: rgba(255, 255, 255, 0.6);
  /*! outright-blue */
  --fg-outright-blue: #133f8e;
  /*! outright-blue-dark */
  --fg-outright-blue-dark: #10326e;
  /** FONT **/
  --main-text-regular: 'main-text-regular';
  --main-text-bold: 'main-text-bold';
  --main-text-link: 'main-text-link';
  --quote: 'quote';
  --button: 'button';
  --all-transition: all 0.4s ease;
  --box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  --text-font-size: 1.2rem;
  --text-line-height: 1.8rem;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-white);
  font-family: 'main-text-regular', sans-serif;
  font-size: 1.2rem;
  font-weight: 100;
}

/** UTILITY: Colors **/
/*! Background Color */
/*! black */
.bg-black {
  background-color: var(--bg-black);
}

/*! highlight-black */
.bg-highlight-black {
  background-color: var(--bg-highlight-black);
}

/*! transparent-black */
.bg-transparent-black {
  background-color: var(--bg-transparent-black);
}

/*! black-muted */
.bg-black-muted {
  background-color: var(--bg-black-muted);
}

/*! white */
.bg-white {
  background-color: var(--bg-white);
}

/*! white-muted */
.bg-white-muted {
  background-color: var(--bg-white-muted);
}

/*! outright-blue */
.bg-outright-blue {
  background-color: var(--bg-outright-blue);
}

/*! outright-blue-dark */
.bg-outright-blue-dark {
  background-color: var(--bg-outright-blue-dark);
}

/*! Foreground Color */
/*! black */
.fg-black {
  color: var(--fg-black);
}

/*! highlight-black */
.fg-highlight-black {
  color: var(--fg-highlight-black);
}

/*! transparent-black */
.fg-transparent-black {
  color: var(--fg-transparent-black);
}

/*! black-muted */
.fg-black-muted {
  color: var(--fg-black-muted);
}

/*! white */
.fg-white {
  color: var(--fg-white);
}

/*! white-muted */
.fg-white-muted {
  color: var(--fg-white-muted);
}

/*! outright-blue */
.fg-outright-blue {
  color: var(--fg-outright-blue);
}

/*! outright-blue-dark */
.fg-outright-blue-dark {
  color: var(--fg-outright-blue-dark);
}

.main-text-regular-12 {
  font-family: 'main-text-regular';
  font-size: 0.75rem;
}

.main-text-regular-14 {
  font-family: 'main-text-regular';
  font-size: 0.875rem;
}

.main-text-regular-16 {
  font-family: 'main-text-regular';
  font-size: 1rem;
}

.main-text-regular-18 {
  font-family: 'main-text-regular';
  font-size: 1.125rem;
}

.main-text-regular-20 {
  font-family: 'main-text-regular';
  font-size: 1.25rem;
}

.main-text-bold-12 {
  font-family: 'main-text-bold';
  font-size: 0.75rem;
}

.main-text-bold-14 {
  font-family: 'main-text-bold';
  font-size: 0.875rem;
}

.main-text-bold-16 {
  font-family: 'main-text-bold';
  font-size: 1rem;
}

.main-text-bold-18 {
  font-family: 'main-text-bold';
  font-size: 1.125rem;
}

.main-text-bold-20 {
  font-family: 'main-text-bold';
  font-size: 1.25rem;
}

.main-text-link-12 {
  font-family: 'main-text-link';
  font-size: 0.75rem;
}

.main-text-link-14 {
  font-family: 'main-text-link';
  font-size: 0.875rem;
}

.main-text-link-16 {
  font-family: 'main-text-link';
  font-size: 1rem;
}

.quote-12 {
  font-family: 'quote';
  font-size: 0.75rem;
}

.quote-14 {
  font-family: 'quote';
  font-size: 0.875rem;
}

.quote-16 {
  font-family: 'quote';
  font-size: 1rem;
}

.quote-18 {
  font-family: 'quote';
  font-size: 1.125rem;
}

.quote-20 {
  font-family: 'quote';
  font-size: 1.25rem;
}

.quote-22 {
  font-family: 'quote';
  font-size: 1.375rem;
}

.quote-24 {
  font-family: 'quote';
  font-size: 1.5rem;
}

.quote-26 {
  font-family: 'quote';
  font-size: 1.625rem;
}

.quote-28 {
  font-family: 'quote';
  font-size: 1.75rem;
}

.button-12 {
  font-family: 'button';
  font-size: 0.75rem;
}

.button-14 {
  font-family: 'button';
  font-size: 0.875rem;
}

.button-16 {
  font-family: 'button';
  font-size: 1rem;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.is-flex {
  display: flex;
}

.is-row {
  flex-direction: row;
}

.is-column {
  flex-direction: column;
}

.float-right {
  justify-content: flex-end;
}

.float-start {
  justify-content: flex-start;
}

.float-center {
  justify-content: center;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

h1 {
  font-family: 'heading';
  font-size: 2rem;
}

h2 {
  font-family: 'heading';
  font-size: 1.75rem;
}

h3 {
  font-family: 'heading';
  font-size: 1.5rem;
}

h4 {
  font-family: 'heading';
  font-size: 1.125rem;
}

h5 {
  font-family: 'heading';
  font-size: 1.125rem;
}

h6 {
  font-family: 'heading';
  font-size: 1rem;
}

.TitleLabel {
  text-transform: lowercase;
  letter-spacing: 10px;
  color: var(--fg-black);
}
.TitleLabel[data-object-type='dark'] {
  color: var(--fg-white);
}
@media (max-width: 480px) {
  .TitleLabel {
    font-size: 22px;
    letter-spacing: 5px;
  }
}
.Button {
  background-color: var(--bg-outright-blue);
  color: var(--fg-white);
  border: none !important;
  cursor: pointer;
  font-family: var(--button);
  letter-spacing: 2.5px;
  padding: 5px 10px;
  text-transform: lowercase;
  font-size: 0.8rem;
  display: flex;
  gap: 10px;
  transition: var(--all-transition);
}
.Button:hover {
  background-color: var(--bg-outright-blue-dark);
  box-shadow: var(--box-shadow);
}
.TextParagraph {
  color: var(--fg-black-muted);
  font-size: var(--text-font-size);
  line-height: var(--text-line-height);
  margin-top: 10px;
  margin-bottom: 10px;
}
.TextParagraph[data-object-type='dark'] {
  color: var(--fg-white-muted);
}
.Icon {
  font-size: 0.8rem;
  color: var(--fg-white);
}
.Icon[data-object-type='blue'] {
  color: var(--fg-outright-blue);
}
.Image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
}
@media (max-width: 480px) {
  .Image {
    display: none;
  }
}
@media (max-width: 768px) {
  .Image {
    display: none;
  }
}
.LanguageSwitch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  color: var(--fg-transparent-black);
}
.LanguageSwitch .separator {
  margin-left: 10px;
  margin-right: 10px;
}
.LanguageSwitch .selector {
  cursor: pointer;
}
.LanguageSwitch .selected {
  font-family: var(--main-text-bold);
  color: var(--fg-black);
}

.Header {
  margin-bottom: 5%;
  min-height: 400px;
  max-height: 400px;
  height: 400px;
}
.Header .logo img {
  height: 80px;
  margin-left: 5%;
  margin-top: -40px;
}
.Header .header-content {
  background-color: var(--bg-black);
  display: flex;
  gap: 20px;
  align-items: center;
}
.Header .header-content .text-content {
  flex: 3;
  padding: 5%;
}
.Header .header-content .image-content {
  width: 300px;
  min-width: 300px;
}
@media (max-width: 768px) {
  .Header {
    margin-bottom: 140px;
  }
  .Header .logo img {
    width: 90%;
    height: auto;
    margin: 20px;
    margin-top: -40px;
  }
  .Header .header-content {
    background-color: var(--bg-black);
  }
  .Header .header-content .text-content {
    padding: 10%;
    padding-bottom: 20%;
  }
  .Header .header-content .image-content {
    display: none !important;
  }
}
.Quote {
  margin-bottom: 5%;
}
.Quote .paragraph-content {
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: var(--bg-outright-blue);
  padding: 1%;
}
.Quote .paragraph-content .text-content {
  background-color: var(--bg-outright-blue);
  flex: 20;
  padding: 7%;
  color: var(--fg-white);
  font-family: 'quote', serif;
  margin-bottom: 20px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-style: italic;
  background-position: top left;
  background-repeat: no-repeat;
}
.Quote .paragraph-content .image-content {
  flex: 4;
}
.Quote .paragraph-content .image-content img {
  position: absolute;
  width: 160px;
  margin-left: -80px;
  margin-top: -134px;
}
@media (max-width: 768px) {
  .Quote .paragraph-content {
    display: flex;
    gap: 0;
    align-items: center;
  }
  .Quote .paragraph-content .text-content {
    background-color: var(--bg-outright-blue);
    flex: 3;
    padding: 7%;
    color: var(--fg-white);
    font-family: 'quote', serif;
    margin-bottom: 20px;
    font-size: 1.2rem;
    line-height: 2.2rem;
    font-style: italic;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 80px 80px;
    text-align: center;
  }
  .Quote .paragraph-content .image-content {
    display: none;
  }
}
.Paragraph {
  margin-bottom: 5%;
}
.Paragraph .paragraph-content {
  display: flex;
  gap: 0;
  align-items: flex-start;
}
.Paragraph .paragraph-content .text-content {
  flex: 2;
  padding: 5%;
}
.Paragraph .paragraph-content .text-content p {
  margin-bottom: 20px;
}
.Paragraph .paragraph-content .image-content {
  flex: 2;
  display: flex;
  margin-top: 5%;
  align-items: flex-start;
  position: relative;
}
.Paragraph .paragraph-content .image-content .content-image {
  height: 300px;
}
.Paragraph .paragraph-content .image-content[data-object-type='right'] {
  justify-content: flex-end;
}
.Paragraph
  .paragraph-content
  .image-content[data-object-type='right']
  .image-overlay {
  z-index: 1000;
  position: absolute;
  left: -40px;
}
.Paragraph .paragraph-content .image-content[data-object-type='left'] {
  justify-content: flex-start;
}
.Paragraph
  .paragraph-content
  .image-content[data-object-type='left']
  .image-overlay {
  z-index: 1000;
  position: absolute;
  right: -35px;
}
@media (max-width: 768px) {
  .Paragraph .paragraph-content {
    display: flex;
    gap: 0;
    align-items: center;
  }
  .Paragraph .paragraph-content .text-content {
    flex: 2;
    padding-left: 10%;
    padding-right: 10%;
  }
  .Paragraph .paragraph-content .text-content p {
    margin-bottom: 20px;
  }
  .Paragraph .paragraph-content .image-content {
    display: none;
  }
}
.BlackParagraph {
  margin-bottom: 5%;
}
.BlackParagraph .paragraph-content {
  background-color: var(--bg-black);
  display: flex;
  gap: 20px;
  align-items: center;
}
.BlackParagraph .paragraph-content .text-content {
  flex: 3;
  padding: 5%;
}
.BlackParagraph .paragraph-content .image-content {
  flex: 1;
  width: 300px;
  min-width: 300px;
}
@media (max-width: 768px) {
  .BlackParagraph .paragraph-content {
    background-color: var(--bg-black);
  }
  .BlackParagraph .paragraph-content .text-content {
    padding: 10%;
    padding-bottom: 20%;
  }
  .BlackParagraph .paragraph-content .image-content {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  .BlackParagraph {
    margin-bottom: 5%;
    min-height: 400px;
    max-height: 400px;
    height: 400px;
  }
}

.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  gap: 20px;
}
.Footer a {
  color: var(--fg-black-muted);
  transition: var(--all-transition);
}
.Footer a:hover {
  color: var(--fg-outright-blue);
}
.Home {
  margin: 12.5%;
}
@media (max-width: 480px) {
  .Home {
    margin: 0;
  }
}

