.Paragraph {
  margin-bottom: 5%;
  //min-height: 400px;
  //max-height: 400px;
  //height: 400px;

  .paragraph-content {
    display: flex;
    gap: 0;
    align-items: flex-start;

    .text-content {
      flex: 2;
      padding: 5%;

      p {
        margin-bottom: 20px;
      }
    }

    .image-content {
      flex: 2;
      display: flex;
      margin-top: 5%;
      align-items: flex-start;
      position: relative;

      .content-image {
        height: 300px;
      }

      &[data-object-type='right'] {
        justify-content: flex-end;

        .image-overlay {
          z-index: 1000;
          position: absolute;
          left: -40px;
        }
      }

      &[data-object-type='left'] {
        justify-content: flex-start;

        .image-overlay {
          z-index: 1000;
          position: absolute;
          right: -35px;
        }
      }
    }
  }
  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
    .paragraph-content {
      display: flex;
      gap: 0;
      align-items: center;

      .text-content {
        flex: 2;
        padding-left: 10%;
        padding-right: 10%;

        p {
          margin-bottom: 20px;
        }
      }

      .image-content {
        display: none;
      }
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
