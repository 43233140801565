.Quote {
  margin-bottom: 5%;

  .paragraph-content {
    display: flex;
    gap: 20px;
    align-items: center;
    background-color: var(--bg-outright-blue);
    //justify-content: center;
    padding: 1%;
    .text-content {
      //height: 300px;
      background-color: var(--bg-outright-blue);
      flex: 20;
      padding: 7%;
      color: var(--fg-white);
      font-family: 'quote', serif;
      margin-bottom: 20px;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-style: italic;
      background-position: top left;
      background-repeat: no-repeat;
    }

    .image-content {
      flex: 4;
      img {
        position: absolute;
        width: 160px;
        margin-left: -80px;
        margin-top: -134px;
      }
    }
  }
  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
    .paragraph-content {
      display: flex;
      gap: 0;
      align-items: center;

      .text-content {
        background-color: var(--bg-outright-blue);
        flex: 3;
        padding: 7%;
        color: var(--fg-white);
        font-family: 'quote', serif;
        margin-bottom: 20px;
        font-size: 1.2rem;
        line-height: 2.2rem;
        font-style: italic;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 80px 80px;
        text-align: center;
      }

      .image-content {
        display: none;
      }
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
