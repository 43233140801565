.TitleLabel {
  text-transform: lowercase;
  letter-spacing: 10px;
  color: var(--fg-black);

  &[data-object-type='dark'] {
    color: var(--fg-white);
  }

  // XS - Phone
  @media (max-width: 480px) {
    font-size: 22px;
    letter-spacing: 5px;
  }

  // S - Tablet
  @media (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
